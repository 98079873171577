import React from "react";
import clsx from "clsx";
import NextLink from "next/link";

import { useCurrentUser } from "@/libs/apis/onboarding/useUser";
import { SiteWrapper } from "../../../../src/components/grid/components/SiteWrapper";
import { useGlobalNavHeadersData } from "@/src/hooks/useGlobalNavHeadersData";
import OptimizelyFeatureFlaggedComponent from "../../OptimizelyFeatureFlaggedComponent";
import { OptimizelyFeatureFlags } from "@/src/services/optimizely/optimizelyFeatureFlags";
import GlobalNav from "nvs-design-system/dist/GlobalNav";
import { useGlobalNavDropdownMenusData } from "@/src/hooks/useGlobalNavDropdownMenusData";
import {
  GlobalNavDropdownMenu,
  GlobalNavHeader,
  GlobalNavProfileMenu,
} from "nvs-design-system/dist/GlobalNav/types";
import { useProfileMenuLinks } from "./constants";
import useCurrentNikeUser from "@/src/routes/account/routes/settings/hooks/useCurrentNikeUser";
import { usePathname, useRouter } from "next/navigation";
import { ROUTE_HOME, ROUTE_WELCOME } from "@/constants/routes";
import { useTrackEvent } from "@/libs/analytics/useAnalytics";
import { createGlobalNavTrackEventPayload } from "nvs-analytics";
import { useHandleSignInClickedGlobalNav } from "@/src/routes/auth/routes/signin/helpers";

const DEFAULT_PROFILE_IMAGE = "/images/profile/default_profile.jpeg";

type GlobalHeaderProps = {
  className?: string;
  position?: "relative" | "absolute";
};

const GlobalHeader = ({
  className,
  position = "relative",
}: GlobalHeaderProps) => {
  const router = useRouter();
  const path = usePathname();
  const { currentUser, isLoading: userIsLoading } = useCurrentUser();
  const { data: globalNavHeaders } = useGlobalNavHeadersData();
  const { data: globalNavDropdownMenus } = useGlobalNavDropdownMenusData(true);

  const isUserLoading = currentUser === undefined;
  const userHasHandle = !!currentUser?.swooshHandle;

  const globalNavPageClicked = useTrackEvent(createGlobalNavTrackEventPayload);
  const signIn = useHandleSignInClickedGlobalNav();

  const onGlobalNavPageClicked = (title: string) => {
    const navPayload = {
      content: {
        title: title,
      },
    };

    return globalNavPageClicked(navPayload);
  };

  const onSignInClick = () => {
    if (path === ROUTE_HOME) {
      router.push(ROUTE_WELCOME);
    } else {
      signIn();
    }
  };

  const profileMenuLinks = useProfileMenuLinks(currentUser?.swooshHandle);

  const profileMenu: GlobalNavProfileMenu = {
    swooshHandle: currentUser?.swooshHandle,
    userImage: currentUser?.avatar?.large ?? DEFAULT_PROFILE_IMAGE, // resolution 200 x 200
    links: profileMenuLinks,
  };

  return (
    <SiteWrapper className={clsx("font-roobert", className)}>
      <OptimizelyFeatureFlaggedComponent
        optimizelyFlags={[OptimizelyFeatureFlags.GLOBAL_NAVIGATION_2_0]}
      >
        <GlobalNav
          className={position}
          headers={globalNavHeaders as GlobalNavHeader[]}
          dropdownMenus={globalNavDropdownMenus as GlobalNavDropdownMenu[]}
          isSignedIn={isUserLoading ? undefined : userHasHandle}
          onSignInClick={onSignInClick}
          profileMenu={profileMenu}
          pageClickedEvent={onGlobalNavPageClicked}
          LinkComponent={NextLink}
        />
      </OptimizelyFeatureFlaggedComponent>
    </SiteWrapper>
  );
};

export default GlobalHeader;
